.glry {
  display: flex;
  flex-direction: column;
  background-color: darkcyan;
  width: 100px;
  height: 30px;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 400px;
  width: 100%;
  border-radius: 40px;
}
